<script setup lang="ts">
import { ref, computed } from "vue";

const props = withDefaults(
  defineProps<{
    type?: "primary" | "secondary" | "ternary";
    to?: string;
    fontSize?: "base" | "lg" | "xl" | "";
    rounded?: "small" | "medium" | "large";
    height?: "" | "small" | "medium" | "large";
    outlined?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    type: "primary",
    to: "",
    fontSize: "lg",
    rounded: "small",
    height: "small",
    outlined: false,
    loading: false,
    disabled: false,
  }
);

defineEmits(["click"]);

const stroke = ref("");

const cssClasses = computed(() => {
  let classes = [];

  switch (props.fontSize) {
    case "base":
      classes.push("text-base");
      break;
    case "lg":
      classes.push("text-lg");
      break;
    case "xl":
      classes.push("text-xl");
      break;
    default:
      classes.push("text-lg");
      break;
  }

  switch (props.rounded) {
    case "small":
      classes.push("rounded-xl");
      break;
    case "medium":
      classes.push("rounded-2xl");
      break;
    case "large":
      classes.push("rounded-full");
      break;
    default:
      classes.push("rounded-xl");
      break;
  }

  switch (props.height) {
    case "small":
      classes.push("h-10");
      break;
    case "medium":
      classes.push("h-11");
      break;
    case "large":
      classes.push("h-12");
      break;
    default:
      classes.push("h-11");
      break;
  }

  if (props.outlined) {
    classes.push("border");
    if (props.type === "primary") {
      classes.push("border-primary-100 text-white");
    }
    if (props.type === "secondary") {
      classes.push("border-secondary text-white");
    }
  } else {
    if (props.type === "primary") {
      classes.push("bg-primary-100 text-white font-semibold");
      stroke.value = "#fff";
    }
    if (props.type === "secondary") {
      classes.push("bg-gray-100 text-black font-semibold");
      stroke.value = "#1d0e0b";
    }
    if (props.type === "ternary") {
      classes.push("bg-gray-100 text-black font-normal");
      stroke.value = "#1d0e0b";
    }
  }
  if (props.disabled && props.type === "primary") {
    classes.push("bg-opacity-40 bg-primary-100 text-white cursor-not-allowed");
  } else if (props.disabled) {
    classes.push("bg-gray-100 text-black text-opacity-10 cursor-not-allowed");
  } else if (props.type === "primary") {
    classes.push("cursor-pointer hover:bg-primary-200");
  } else {
    classes.push("cursor-pointer hover:bg-gray-800");
  }
  return classes.join(" ");
});
</script>

<template>
  <nuxt-link
    v-if="to"
    :to="to"
    class="px-4 rounded hover:cursor-pointer font-sf-pro text-center flex relative"
    :class="cssClasses"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span
      class="m-auto pb-px z-10"
      :class="[!loading ? 'opacity-100' : 'opacity-0']"
    >
      <slot />
    </span>
    <span
      v-if="loading"
      class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-20"
    >
      <t-icon-preloader :color="stroke"></t-icon-preloader>
    </span>
  </nuxt-link>
  <button
    v-else
    class="px-4 rounded font-sf-pro text-center flex relative"
    :class="cssClasses"
    :disabled="disabled"
    @click="!disabled ? $emit('click') : ''"
  >
    <span class="m-auto z-10" :class="[!loading ? 'opacity-100' : 'opacity-0']">
      <slot />
    </span>
    <span
      v-if="loading"
      class="flex items-center h-full w-full justify-center left-0 top-0 absolute z-20"
    >
      <t-icon-preloader :color="stroke"></t-icon-preloader>
    </span>
  </button>
</template>
